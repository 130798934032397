<template>
	<v-select
		v-model="workspaces"
		clearable
		item-text="name"
		item-value="id"
		:items="workspacesList"
		:label="$t('holding.workspaces')"
		:loading="loading"
		multiple
		style="width: 100%"
	>
		<template v-slot:prepend-item>
			<v-list-tile ripple @click.stop="onMainSelectorClick()">
				<v-list-tile-action>
					<v-checkbox color="primary" false-value="none" :indeterminate="selectionState == 'some'" :input-value="selectionState" true-value="all" />
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>{{ $t('holding.select_all_workspaces') }}</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>
			<v-divider class="mt-2"></v-divider>
		</template>
		<template v-slot:selection="{ item, index }">
			<span v-if="workspaces.length == 1" v-text="item.name" />
			<span v-else-if="workspaces.length > 1 && index == 0" v-text="$tc('holding.workspaces_counter', workspaces.length, { count: workspaces.length })" />
			<span v-else-if="workspaces.length == 0" v-text="$tc('holding.workspaces_counter', 0)" />
		</template>
	</v-select>
</template>

<script>
import AppService from '@/services/AppService'

export default {
	name: 'WorkspaceFilter',
	props: {
		items: {
			default: () => [],
			required: false,
			type: Array
		},
		loading: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Array
		}
	},
	computed: {
		selectionState: function () {
			if (this.items.length == 0 || this.workspaces.length == 0) {
				return 'none'
			} else if (this.items.length == this.workspaces.length) {
				return 'all'
			} else {
				return 'some'
			}
		},
		workspaces: {
			get: function () {
				return this.value
			},
			set: function (workspaces) {
				this.$emit('input', workspaces)
			}
		},
		workspacesList: function () {
			const result = [...this.items]
			return result.sort(AppService.compareValues('name', 'asc'))
		}
	},
	watch: {
		items: {
			handler: 'selectEveryWorkspaces'
		}
	},
	mounted: function () {
		this.selectEveryWorkspaces()
	},
	methods: {
		onMainSelectorClick: function () {
			if (this.selectionState != 'all') {
				this.selectEveryWorkspaces()
			} else {
				this.unselectEveryWorkspaces()
			}
		},
		selectEveryWorkspaces: function () {
			this.workspaces = this.items.map(({ id }) => id)
		},
		unselectEveryWorkspaces: function () {
			this.workspaces.splice(0, this.workspaces.length)
		}
	}
}
</script>